import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { JsonData } from "../components/jsonData";
import { Loading } from "../components/loading";
import { ErrorComponent } from "../components/errorComponent";
import { getAppConfigSettings } from "../utils/customApiCall";

import Paper from '@mui/material/Paper';

const AppConfigContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const request = {
              ...apiRequest,
              account: account
          };
          instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
              window.localStorage.setItem("authClaims", JSON.stringify(response.account.idTokenClaims));
              getAppConfigSettings(response.accessToken).then(response => setGraphData(response));
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <Paper>
          { graphData ? <JsonData graphData={graphData} /> : null }
      </Paper>
  );
};

const AppConfigPage = () => {
  const authRequest = {
      ...apiRequest
  };

  return (
      <Layout>
        <Seo title="App Config" />
        <h1>App Config</h1>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={Loading}
        >
            <AppConfigContent />
        </MsalAuthenticationTemplate>
        <Link to="/">Go back to the homepage</Link>
      </Layout>
    )
};

export default AppConfigPage
